import React, { memo } from 'react';
import { FlatList, Text, View } from 'react-native';

import { useSyncContext } from '@/contexts/syncContext';
import { DateFormatter } from '@/helpers/DateFormatter';
import type { ISyncAudio } from '@/libs/types/sync';

import { Card } from './components/Card';
import { styles } from './styles';

type ISyncListProps = {
  data: ISyncAudio[];
  disableButtons: boolean;
  onClick: (audio: string, interview: number) => void;
};

function ListComponent({ data, disableButtons, onClick }: ISyncListProps) {
  const { lastUpdate } = useSyncContext();

  const emptyList = () => {
    return (
      <View style={styles.allSynchronizedContainer}>
        <Text>Sem audio para sincronizar</Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.lastSync}>
        Última sincronização:{' '}
        {lastUpdate
          ? `${DateFormatter.fullDate(
              lastUpdate.getTime(),
            )} • ${DateFormatter.hoursAndMinutes(lastUpdate.getTime())}`
          : '-'}
      </Text>

      <FlatList
        data={data}
        contentContainerStyle={styles.listContainer}
        keyExtractor={(item) => item.interviewId.toString()}
        renderItem={({ item }) => {
          const { interviewId, synced, audioId } = item;

          return (
            <Card
              interviewId={interviewId}
              synced={synced}
              audioId={audioId}
              disableButton={disableButtons}
              onClick={onClick}
            />
          );
        }}
        ListEmptyComponent={() => emptyList()}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
}

export const AudioList = memo(ListComponent);
