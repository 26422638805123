import { CheckCircle2, CircleEllipsis, XCircle } from 'lucide-react-native';
import React, { memo } from 'react';
import { Pressable, Text, View } from 'react-native';

import { useSyncContext } from '@/contexts/syncContext';
import { Color } from '@/styles/GlobalStyles';

import { styles } from './styles';

type ISyncCardComponentProps = {
  interviewId: number;
  audioId: string;
  synced: boolean;
  disableButton: boolean;
  onClick: (audio: string, interview: number) => void;
};

export function CardComponent({
  interviewId,
  synced,
  audioId,
  disableButton,
  onClick,
}: ISyncCardComponentProps) {
  const { isSyncing } = useSyncContext();

  const statusIcon = () => {
    if (synced) {
      return (
        <View style={styles.statusContainer}>
          <CheckCircle2
            style={styles.statusIcon}
            fill={Color.secondary.green}
          />
          <Text style={[styles.textDefault, styles.textNormal]}>
            Sincronizado
          </Text>
        </View>
      );
    }

    if (isSyncing) {
      return (
        <View style={styles.statusContainer}>
          <CircleEllipsis
            style={styles.statusIcon}
            fill={Color.secondary.yellow}
          />
          <Text style={[styles.textDefault, styles.textNormal]}>
            Sincronizando
          </Text>
        </View>
      );
    }

    return (
      <View style={styles.statusContainer}>
        <XCircle style={styles.statusIcon} fill={Color.secondary.red} />
        <Text style={[styles.textDefault, styles.textNormal]}>
          Falha de envio
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.idFormNameContainer}>
        <Text style={[styles.textDefault, styles.textSemiBold, styles.id]}>
          ID da entrevista: {interviewId}
        </Text>
      </View>

      <View style={styles.statusContainer}>
        <Text style={[styles.textDefault, styles.textNormal]}>
          {statusIcon()}
        </Text>
      </View>

      {!synced && (
        <View style={styles.statusContainer}>
          <Pressable
            onPress={() => onClick(audioId, interviewId)}
            disabled={disableButton}
            style={[
              styles.buttons,
              disableButton ? styles.disabledButtons : styles.enabledButtons,
            ]}
          >
            <Text style={[styles.buttonText]}>Sincronizar áudio</Text>
          </Pressable>
        </View>
      )}
    </View>
  );
}

export const Card = memo(CardComponent);
