/* eslint-disable no-param-reassign */
import { Audio } from 'expo-av';
import { openDB } from 'idb';
import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import * as Sentry from 'sentry-expo';

import { database } from '@/database';
import type { AudioModel } from '@/database/model/Audio';
import type { UserModel } from '@/database/model/User';

import { version as APP_VERSION } from '../../package.json';

interface IContext {
  children: ReactNode;
}

interface IAudioContext {
  startRecording: (setTimer: (time: number) => void) => void;
  stopAudioRecording: (interviewId: string) => void;
  isRecording: boolean;
}

export const AudioContext = createContext({} as IAudioContext);

export default function AudioContextProvider({ children }: IContext) {
  const [recordingAudio, setRecordingAudio] = useState<any>();
  const [isRecording, setIsRecording] = useState(false);
  const [permissionResponse, requestPermission] = Audio.usePermissions();

  const blobToBase64 = (audioBlob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64String = (reader.result as string).split(',')[1] || ''; // Evita erro de null
          resolve(base64String);
        } else {
          reject(
            new Error(
              'Falha ao converter Blob para Base64: reader.result é null',
            ),
          );
        }
      };

      reader.onerror = () => {
        reject(new Error('Erro ao ler o Blob'));
      };

      reader.readAsDataURL(audioBlob);
    });
  };

  const initDB = async () => {
    const db = await openDB('AudioDB', 1, {
      upgrade(dbUpgrade) {
        if (!dbUpgrade.objectStoreNames.contains('recordings')) {
          dbUpgrade.createObjectStore('recordings', {
            keyPath: 'id',
            autoIncrement: true,
          });
        }
      },
    });
    return db;
  };

  const startRecording = async (setTimer: (time: number) => void) => {
    const userCollection = database.get<UserModel>('users');
    const userData = await userCollection.query().fetch();
    const userId = userData[0].user_id;
    try {
      if (permissionResponse && permissionResponse.status !== 'granted') {
        console.log('Requesting permission..');
        await requestPermission();
      }

      await Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
        staysActiveInBackground: true,
        shouldDuckAndroid: false,
      });

      const { recording } = await Audio.Recording.createAsync({
        android: {
          extension: '.m4a',
          outputFormat: Audio.AndroidOutputFormat.MPEG_4,
          audioEncoder: Audio.AndroidAudioEncoder.AAC,
          sampleRate: 44100,
          numberOfChannels: 1,
          bitRate: 128000,
        },
        ios: {
          extension: '.m4a',
          outputFormat: Audio.IOSOutputFormat.MPEG4AAC,
          audioQuality: Audio.IOSAudioQuality.MEDIUM,
          sampleRate: 44100,
          numberOfChannels: 1,
          bitRate: 128000,
          linearPCMBitDepth: 16,
          linearPCMIsBigEndian: false,
          linearPCMIsFloat: false,
        },
        web: {
          mimeType: 'audio/webm',
          bitsPerSecond: 128000,
        },
      });

      setRecordingAudio(recording);
      setIsRecording(true);

      recording.setOnRecordingStatusUpdate(null);

      recording.setOnRecordingStatusUpdate((status: any) => {
        try {
          const currentTime = Math.floor(status.durationMillis / 1000);
          console.log(
            `Recording time: ${currentTime}s, isRecording: ${status.isRecording}`,
          );
          if (status.isDoneRecording) {
            console.log('Recording finished automatically');
          }
          setTimer(currentTime);
        } catch (error) {
          Sentry.Browser.captureEvent({
            message: 'Failed to update audio',
            user: {
              id: userId,
            },
            contexts: {
              app: {
                app_version: APP_VERSION,
              },
            },
            level: 'error',
            extra: {
              err: error,
              userId,
              appVersion: APP_VERSION,
            },
          });
        }
      });

      console.log('Recording started');
    } catch (err) {
      console.error('Failed to start recording', err);
      Sentry.Browser.captureEvent({
        message: 'Failed to start recording',
        user: {
          id: userId,
        },
        contexts: {
          app: {
            app_version: APP_VERSION,
          },
        },
        level: 'error',
        extra: {
          err,
          userId,
          appVersion: APP_VERSION,
        },
      });
    }
  };

  const stopAudioRecording = async (interviewId: string) => {
    console.log('Stopping recording..');
    if (recordingAudio) {
      const recording = recordingAudio;
      setRecordingAudio(undefined);
      recording.setOnRecordingStatusUpdate(null);
      await recordingAudio.stopAndUnloadAsync();
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
      });

      const userCollection = database.get<UserModel>('users');
      const userResponse = await userCollection.query().fetch();
      const userId = userResponse[0].user_id;
      try {
        const db = await initDB();

        const uri = recordingAudio.getURI();
        console.log('Recording URI:', uri);

        const response = await fetch(uri);
        const blob = await response.blob();
        const audioBase64 = await blobToBase64(blob);
        console.log('audioBase64:', audioBase64);

        const tx = db.transaction('recordings', 'readwrite');
        const store = tx.objectStore('recordings');
        const id = await store.put({ audio: audioBase64, interviewId });
        await tx.done;
        console.log('Audio saved to IndexedDB with id:', id);

        const savedAudio = await db.get('recordings', id);
        const audioString = savedAudio.audio;

        await database.write(async () => {
          await database.get<AudioModel>('audios').create((audio) => {
            audio.audio = audioString;
            audio.interview_id = interviewId;
            audio.created_by = userId;
            audio.updated_by = userId;
            audio.created_at = new Date().getTime();
            audio.updated_at = new Date().getTime();
            audio.synced = false;
          });
        });

        console.log('Audio successfully saved in both DBs with ID:', id);
        return true;
      } catch (error) {
        console.error('Error saving audio:', error);
        Sentry.Browser.captureEvent({
          message: 'Failed to stop recording',
          user: {
            id: userId,
          },
          contexts: {
            app: {
              app_version: APP_VERSION,
            },
          },
          level: 'error',
          extra: {
            err: error,
            userId,
            appVersion: APP_VERSION,
          },
        });
        return null;
      }
    }
    return null;
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    startRecording,
    stopAudioRecording,
    isRecording,
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
}

export const useAudioContext = () => useContext(AudioContext);
