import { useIsFocused } from '@react-navigation/core';
import React, { memo, useEffect, useState } from 'react';
import { Text, useWindowDimensions, View } from 'react-native';

import { useSyncContext } from '@/contexts/syncContext';
import { database } from '@/database';
import type { AudioModel } from '@/database/model/Audio';

import { styles } from './styles';

function AudioCardComponent() {
  const [totalAudios, setTotalAudios] = useState<number>(0);
  const [syncedAudiosCount, setSyncedAudiosCount] = useState<number>(0);
  const { audioIsSyncing } = useSyncContext();
  const { width } = useWindowDimensions();
  const isMobile = width < 540;
  const isFocused = useIsFocused();

  useEffect(() => {
    const getAudios = async () => {
      const audiosData = await database.get<AudioModel>('audios').query();
      setTotalAudios(audiosData.length);
      const syncedCount = audiosData.filter((audio) => audio.synced);
      setSyncedAudiosCount(syncedCount.length);
    };

    getAudios();
  }, [isFocused, audioIsSyncing]);

  return (
    <View style={[styles.container, isMobile && styles.containerMobile]}>
      <View
        style={[styles.cardContainer, isMobile && styles.cardContainerMobile]}
      >
        <Text style={styles.number}>
          {syncedAudiosCount} / {totalAudios}
        </Text>
        <Text style={styles.label}>Audios sincronizados</Text>
      </View>
    </View>
  );
}

export const AudioCard = memo(AudioCardComponent);
