import { Q } from '@nozbe/watermelondb';
import { useIsFocused } from '@react-navigation/native';
import React, { memo, useEffect, useState } from 'react';
import { useToast } from 'react-native-toast-notifications';
import * as Sentry from 'sentry-expo';

import { useSyncContext } from '@/contexts/syncContext';
import { database } from '@/database';
import type { AudioModel } from '@/database/model/Audio';
import type { InterviewModel } from '@/database/model/Interview';
import type { ISyncAudio } from '@/libs/types/sync';

import { AudioList } from '../../AudioList';

function AudiosComponent() {
  const isFocused = useIsFocused();
  const [audios, setAudios] = useState<ISyncAudio[]>([]);
  const { isSyncing, individualAudioSync } = useSyncContext();
  const [disableButtons, setDisableButtons] = useState(false);
  const toast = useToast();

  const handleClick = async (audio: string, interview: number) => {
    setDisableButtons(true);
    try {
      await individualAudioSync(audio);
    } catch (error) {
      toast.show('Erro ao sincronizar o áudio', {
        type: 'danger',
        placement: 'top',
        duration: 4000,
      });
      Sentry.Browser.captureEvent({
        message: 'erro ao sincronizar audio',
        level: 'error',

        extra: {
          error,
          audio,
          interview,
        },
      });
    } finally {
      setDisableButtons(false);
    }
  };

  useEffect(() => {
    const checkSyncData = async () => {
      const audiosData = await database.get<AudioModel>('audios').query();
      const interviewIds = [
        ...new Set(
          audiosData.map((audio) => audio.interview_id).filter((id) => !!id),
        ),
      ];

      // Buscar todas as entrevistas de uma vez usando Q.oneOf
      const interviews =
        interviewIds.length > 0
          ? await database
              .get<InterviewModel>('interviews')
              .query(Q.where('id', Q.oneOf(interviewIds)))
              .fetch()
          : [];

      const audiosWithInterviews = audiosData.map((audio) => {
        const interview = interviews.find(
          (interviewData) => interviewData.id === audio.interview_id,
        );
        return {
          interviewId: interview?.interview_id || 0,
          synced: audio.synced,
          audioId: audio.id,
        };
      });
      setAudios(audiosWithInterviews);
    };
    checkSyncData();
  }, [isFocused, isSyncing, disableButtons]);

  return (
    <AudioList
      data={audios}
      onClick={handleClick}
      disableButtons={disableButtons}
    />
  );
}

export const Audios = memo(AudiosComponent);
