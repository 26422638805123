import { ClerkProvider } from '@clerk/clerk-react';
import { CLERK_PUBLISHABLE_KEY } from '@env';
import {
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  useFonts,
} from '@expo-google-fonts/inter';
import { Roboto_400Regular, Roboto_500Medium } from '@expo-google-fonts/roboto';
import AppLoading from 'expo-app-loading';
import * as React from 'react';
import { ToastProvider } from 'react-native-toast-notifications';
import * as Sentry from 'sentry-expo';

import AudioContextProvider from '@/contexts/AudioContext';
import InterviewContextProvider from '@/contexts/InterviewContext';
import SyncContextProvider from '@/contexts/syncContext';
import { Routes } from '@/routes';

import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';

Sentry.init({
  dsn: 'https://90cfa0ab151e46a73fcb88e7bff8c217@o4506370948333568.ingest.sentry.io/4506371048734720',
  enableInExpoDevelopment: true,
  enableTracing: true,
  tracesSampleRate: 1.0, // Garante que o tracing esteja ativo
});

const App = () => {
  const [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Roboto_400Regular,
    Roboto_500Medium,
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <SyncContextProvider>
      <ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
        <AudioContextProvider>
          <InterviewContextProvider>
            <ToastProvider>
              <Routes />
            </ToastProvider>
          </InterviewContextProvider>
        </AudioContextProvider>
      </ClerkProvider>
    </SyncContextProvider>
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

export default Sentry.Browser.withProfiler(App);
