/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import { API_BASE_URL, BLOB_READ_WRITE_TOKEN } from '@env';
import { Q } from '@nozbe/watermelondb';
import { put } from '@vercel/blob';
import axios from 'axios';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';
import * as Sentry from 'sentry-expo';

import { database } from '@/database';
import type { AudioModel } from '@/database/model/Audio';
import { syncAnswers } from '@/services/Synchronize';

interface IContext {
  children: ReactNode;
}

interface ISyncContext {
  isSyncing: boolean;
  setSync: () => Promise<boolean>;
  isModalVisible: boolean;
  handleShowIsModalVisible: (_visible: boolean) => void;
  lastUpdate: Date | undefined;
  cancelIsSyncing: () => void;
  audioIsSyncing: { status: boolean; error: boolean };
  audioStartedSync: boolean;
  individualAudioSync: (audio: string) => void;
}

export const SyncContext = createContext({} as ISyncContext);
const axiosInstance = axios.create({ baseURL: API_BASE_URL });
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

function writeString(view: DataView, offset: number, string: string): void {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

// Função auxiliar para converter AudioBuffer para WAV
function audioBufferToWav(buffer: AudioBuffer): ArrayBuffer {
  const numOfChan = buffer.numberOfChannels;
  const length = buffer.length * numOfChan * 2;
  const buffer2 = new ArrayBuffer(44 + length);
  const view = new DataView(buffer2);
  const channels = [];
  let sample;
  let offset = 0;
  let pos = 0;

  // Write WAV header
  writeString(view, 0, 'RIFF');
  view.setUint32(4, 36 + length, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, numOfChan, true);
  view.setUint32(24, buffer.sampleRate, true);
  view.setUint32(28, buffer.sampleRate * 2 * numOfChan, true);
  view.setUint16(32, numOfChan * 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, 'data');
  view.setUint32(40, length, true);

  // Write PCM audio data
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < buffer.numberOfChannels; i++) {
    channels.push(buffer.getChannelData(i));
  }

  while (pos < buffer.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < numOfChan; i++) {
      sample = Math.max(-1, Math.min(1, channels[i][pos]));
      // eslint-disable-next-line no-bitwise
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0;
      view.setInt16(44 + offset, sample, true);
      offset += 2;
    }
    pos += 1;
  }

  return buffer2;
}

export default function SyncContextProvider({ children }: IContext) {
  const [isSyncing, setIsSyncing] = useState(false);
  const [audioIsSyncing, setAudioIsSyncing] = useState({
    status: false,
    error: false,
  });
  const [audioStartedSync, setAudioStartedSync] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [lastUpdate, setLastUpdate] = useState<Date>();

  const retryRequest = async (
    fn: () => Promise<any>,
    retries = MAX_RETRIES,
    delay = RETRY_DELAY,
  ): Promise<any> => {
    for (let attempt = 0; attempt < retries; attempt += 1) {
      try {
        return await fn();
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed:`, error);
        if (attempt < retries - 1) {
          await new Promise((resolve) =>
            setTimeout(resolve, delay * 2 ** attempt),
          ); // Exponential backoff
        } else {
          throw error;
        }
      }
    }
    throw new Error('Max retries reached');
  };

  const compressAudioBase64 = async (base64Audio: string): Promise<string> => {
    // Converter base64 para Blob
    const byteCharacters = atob(base64Audio);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const audioBlob = new Blob([byteArray], { type: 'audio/m4a' });

    // Criar um elemento de áudio para processamento
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(await audioBlob.arrayBuffer());

    // Configurar o processador de compressão com a duração correta
    const offlineContext = new OfflineAudioContext({
      numberOfChannels: audioBuffer.numberOfChannels,
      length: audioBuffer.length,
      sampleRate: audioBuffer.sampleRate, // Manter o sample rate original
    });

    // Criar source node
    const source = offlineContext.createBufferSource();
    source.buffer = audioBuffer;

    // Criar compressor com configurações mais suaves
    const compressor = offlineContext.createDynamicsCompressor();
    compressor.threshold.value = -24;
    compressor.knee.value = 30;
    compressor.ratio.value = 4;
    compressor.attack.value = 0.003;
    compressor.release.value = 0.25;

    // Conectar nodes
    source.connect(compressor);
    compressor.connect(offlineContext.destination);

    // Processar áudio
    source.start(0);
    const renderedBuffer = await offlineContext.startRendering();

    console.log('Original duration:', audioBuffer.duration);
    console.log('Compressed duration:', renderedBuffer.duration);

    // Converter buffer para WAV mantendo as configurações originais
    const wavBuffer = audioBufferToWav(renderedBuffer);
    const compressedBlob = new Blob([wavBuffer], { type: 'audio/wav' });

    // Converter blob para base64
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = (reader.result as string).split(',')[1];
        resolve(base64data);
      };
      reader.readAsDataURL(compressedBlob);
    });
  };

  const syncSigleAudio = async (audio: AudioModel) => {
    const plainAudio = {
      interview_id: audio.interview_id,
      created_by: audio.created_by,
      updated_by: audio.updated_by,
      audio: audio.audio,
      created_at: audio.created_at,
      updated_at: audio.updated_at,
    };

    try {
      // Comprimir o áudio antes do upload
      const compressedAudio = await compressAudioBase64(plainAudio.audio);

      const { url } = await retryRequest(() =>
        put(`${plainAudio.interview_id}.wav`, compressedAudio, {
          access: 'public',
          token: BLOB_READ_WRITE_TOKEN,
        }),
      );

      const response = await retryRequest(() =>
        axiosInstance.post(
          '/audio/save',
          JSON.stringify({ ...plainAudio, audio: url }),
        ),
      );

      if (response.status === 201) {
        await database.write(async () => {
          audio.update((record) => {
            // eslint-disable-next-line no-param-reassign
            record.synced = true;
          });
        });
      }
      return response;
    } catch (error) {
      console.error('Erro ao processar e enviar áudio:', error);
      throw error;
    }
  };

  const individualAudioSync = async (audioId: string) => {
    const audioData = await database
      .get<AudioModel>('audios')
      .query(Q.where('id', audioId))
      .fetch();
    if (audioData[0]) {
      await syncSigleAudio(audioData[0]);
    } else {
      throw new Error('Audio nâo encontrado');
    }
  };

  const syncAudios = async () => {
    setAudioStartedSync(true);
    const audios = await database.get<AudioModel>('audios').query();
    const asyncedAudios = audios.filter((audio) => !audio.synced);
    if (asyncedAudios.length) {
      try {
        setAudioIsSyncing({ status: true, error: false });

        for (const audio of asyncedAudios) {
          await syncSigleAudio(audio);
        }
        setAudioIsSyncing({ status: false, error: false });
      } catch (error: any) {
        console.error('Erro ao sincronizar os áudios:', error);
        setAudioIsSyncing({ status: false, error: true });

        Sentry.Browser.captureException(error, {
          level: 'error',
          extra: {
            error,
            message: 'Erro ao sincronizar os áudios',
          },
        });
      } finally {
        setTimeout(() => {
          setAudioStartedSync(false);
        }, 100);
      }
    }
  };

  const handleShowIsModalVisible = useCallback((visible: boolean) => {
    setIsModalVisible(visible);
  }, []);

  const cancelIsSyncing = () => {
    setIsSyncing(false);
  };

  const setSync = async () => {
    if (!isSyncing) {
      setIsSyncing(true);
      await syncAnswers();
      await syncAnswers();
      syncAudios();
      setIsSyncing(false);
      setLastUpdate(new Date());
      return true;
    }
    return false;
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isSyncing,
    setSync,
    isModalVisible,
    handleShowIsModalVisible,
    lastUpdate,
    cancelIsSyncing,
    audioIsSyncing,
    audioStartedSync,
    individualAudioSync,
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <SyncContext.Provider value={value}>{children}</SyncContext.Provider>
  );
}

export const useSyncContext = () => useContext(SyncContext);
